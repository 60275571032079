/* Additional styles for the sample components */
h2 {
    margin-top: 0;
  }
  
  .App {
    background-color: white;
  }
  
  .Auth-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: linear-gradient(to top, rgba(255, 255, 255, 0) 5%, rgba(255, 255, 255, 1)),
              url('../assets/background.jpg') bottom center no-repeat;
    background-size: cover;
    min-height: 100vh; /* Make sure the container covers the entire viewport height */
  }
  
  .Auth-form {
    width: 420px;
    box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
    padding-top: 50px;
    padding-bottom: 20px;
    border-radius: 8px;
    background-color: white;
  }
  
  .Auth-form-content {
    padding-left: 12%;
    padding-right: 12%;
  }
  
  .Auth-form-title {
    text-align: center;
    margin-bottom: 1em;
    font-size: 24px;
    color: rgb(34, 34, 34);
    font-weight: 800;
  }
  
  label {
    font-size: 14px;
    font-weight: 600;
    color: rgb(34, 34, 34);
  }

  .d-grid.gap-2.mt-3 {
    padding-bottom: 30px;
  }

  .Auth-form-container.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }

  .logo-icon-auth {
    height: 35px;
    padding-right: 25px;
    
  }

  .logo-icon-auth-latitud {
    height: 35px;
    
    padding-left: 40px;
  }

  #email-input, #password-input {
    width: 320px;
    padding: 10px;
    margin: 5px 0 0px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
 }

 .logo-container {
  display: flex;
  padding-bottom: 20px;

 }

 .logo-container {
  display: flex;
  align-items: center;
  
}


.logo-separator {
  height: 60px; /* Adjust the height as needed */
  width: 2px; /* Thin line separator */
  background: linear-gradient(to bottom, #ff4e50, #f9d423); /* Gradient effect */
  box-shadow: 0 0 10px rgba(0,0,0,0.5); /* Shadow effect */
  margin: 0 20px; /* Adjust spacing around the separator */
}