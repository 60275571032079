.varieties-manager {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 700px;
    margin: 0 auto;
}

.varieties-manager h3 {
    color: #333;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
}

.varieties-manager input[type="text"] {
    width: 100%;
    padding: 12px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: 16px;
}

.varieties-manager button {
    width: 100%;
    padding: 12px;
    background-color: rgb(93, 120, 165);
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: background-color 0.3s ease;
    margin: 10px 0;
}

.varieties-manager button:hover {
    background-color: rgb(93, 120, 165);
}

.scrollable-container {
    max-height: 600px;
    overflow-y: auto;
    width: 100%;
}

.varieties-manager ul {
    list-style: none;
    padding: 0;
    width: 100%;
}

.varieties-manager li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 5px 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.varieties-manager .variety-name {
    display: flex;
    align-items: center;
    width: 100%;
}

.varieties-manager .name-label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
}

.varieties-manager .button-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 30%;
    
}

.varieties-manager li button {
    background-color: #cd7763;
    padding: 8px 12px;
    border: none;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    margin-left: 10px;
}

.varieties-manager li button:hover {
    background-color: #c82333;
}

.varieties-manager .description-button {
    background-color: rgb(93, 120, 165);
    padding: 8px 12px;
    border: none;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
    width: 80px;
}

.varieties-manager .description-button:hover {
    background-color: #0056b3;
}

.varieties-manager .variety-description {
    display: none;
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 8px;
    margin-top: 10px;
    width: 100%;
}

.varieties-manager .variety-description.show {
    display: block;
}

.message {
    color: red;
    font-weight: bold;
    margin-bottom: 10px;
}   

.edit-name {
    width: 100px !important;
}

.variety-label {
    width: 70%;
}

.saveName {
    width: 110px !important;
}

.cancelName {
    width: 110px !important;
}


.varieties-manager .button-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 30%;
}

.varieties-manager .editNameGroup {
    display: flex;
    gap: 10px; /* Add space between save and cancel buttons */
    margin-left: 10px; /* Optional: Adjust the spacing between this group and other elements */
}

.varieties-manager li button {
    background-color: #cd7763;
    padding: 8px 12px;
    border: none;
    border-radius: 8px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.varieties-manager .saveName,
.varieties-manager .cancelName {
    width: 110px !important;
}

.varieties-manager .edit-name {
    width: 100px !important;
}

.varieties-manager .description-button {
    width: 80px;
}

.varieties-manager .variety-name {
    display: flex;
    align-items: center;
    width: 100%;
}

.varieties-manager .name-label {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.varieties-manager .edit-name,
.varieties-manager .description-button {
    margin-left: 10px; /* Ensures consistent spacing between buttons */
}

.scrollable-container {
    max-height: 600px;
    overflow-y: auto;
    width: 100%;
}

.message {
    color: red;
    font-weight: bold;
    margin-bottom: 10px;
}