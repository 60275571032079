

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5%;
  padding-bottom: 25px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 300px;

}


label {
  margin-bottom: 5px;
}

input {
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0069d9;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Center vertically */
  height: 100vh; /* Set the height to the viewport height */
  padding: 20px;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
  padding-bottom: 25px;
}


.btn-primary {
  background-color: rgb(93, 120, 165) !important; /* Your preferred color */
  border-color: rgb(93, 120, 165) !important;
}

.error-message {
  color: rgb(200, 78, 78);
  margin: 10px 0;
  text-align: center;
  font-size: 14px;
}

.variety-label {
  width: 100px;
  padding-right: 20px;
}

.variety-select {
  width: 200px;
 text-align: center;;
}

.variety-other {
  width: 200px;
  
}


.variety-select,
.custom-variety-input {
  width: 100%; /* Adjust width as needed */
}

.custom-variety-input {
  margin-top: 0.5rem; /* Adds some space between select and input */
}