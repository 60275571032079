.sample-image-row {
    display: flex;
    align-items: center;
    height: 80px;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px; /* Add desired padding */
    padding-left: 50px;
    padding-right: 50px;
    border: 2px solid darkgrey; /* Add desired border style */
    margin-bottom: 10px; /* Add desired margin between rows */
    /* Add any other styles for the sample image row */
    /*justify-content: space-between;*/
    align-items: center;
  }

/* Sample Image Index */
.sample-image-id {
    /* Add styles for the number */
    display: flex;
    align-items: center;
    width: 300px;
  
  }

.sample-image-date {
    /* Add styles for the number */
    display: flex;
    padding-right: 20px;
    width: 200px;
    height: 30px;
    align-items: center;
  }

  .sample-image-hydration {
    /* Add styles for the number */
    display: flex;
    padding-right: 20px;
    width: 160px;
    font-size: 16px;
  }

  .sample-image-capacity {
    /* Add styles for the number */
    display: flex;
    padding-right: 20px;
    width: 355px;
    font-size: 16px;
  }

@media (max-width: 1024px) {
    .sample-hydration {
        font-size: 14px; /* Smaller font size for screens less than 600px wide */
    }
}

  .sample-image-open {
    /* Add styles for the number */
    margin-left: 10px; 
  }

.sample-image-delete {
    /* Add styles for the number */
    
    background-color: #cd7763 !important;
    border-color: rgb(93, 120, 165) !important;
    border-width: 2px !important;
    /* #e9c397*/
}  

.fade-out {
  animation: fadeOut 0.5s ease-out forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }
}

/* Media Query for screens smaller than 1024px */
@media (max-width: 1024px) {
  .sample-image-id, .sample-image-date, .sample-image-hydration, .sample-image-capacity {
      font-size: 14px; /* Smaller font size for screens less than 1024px wide */
  }
}