.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* App.css */

.landing-page-container {
  display: flex;
  height: 100vh;
  overflow: hidden; /* Hide overflow from the portal container */
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.landing-page-container.visible {
  opacity: 1;
}

.content-area {
  flex-grow: 1; /* Allow the content area to grow and occupy remaining space */
  background-color: #f5f5f5; /* Change to your desired background color */

}

.h2 {
  font-size: 1.5rem;
}

.h3 {
  font-size: 1rem;
}

.left-menu {
  width: 20%;
  background-color: #495057;
  min-width: 250px;
  position: fixed; /* Fixed positioning for the left menu */
  top: 0;
  bottom: 0;
  left: 0;
}

.content-area {
  flex-grow: 1;
  background-color: #f5f5f5;
  overflow-y: auto; /* Add vertical scrollbar to the content area */
  overflow-y: auto; /* Add vertical scrollbar to the content area */
  margin-left: 20%; /* Adjust margin to match the width of the left menu */
}

.flag {
  width: 24px;
  height: 18px;
  border: none;
  background: none;

}

.flag-button {
  background: none;
}

.language-button img {
  display: block;
}

.active-language {
  box-shadow: inset 0 0 5px #000;
}

.inactive-language {
  opacity: 0.5;
}





