.samples-container {
    height: 100%;
    padding: 15px;
    
}

.samples-view-title {
    text-align: center; /* Center align the content */
    padding: 15px;
}


.samples-view-container {
    padding-left: 20px;
    padding-bottom: 50px;
}

@media screen and (max-width: 1024px) {
    .samples-rows {
      padding-left: 25px;
    }
  }

@media screen and (max-width: 1024px) {
.page-container {
    padding-left: 25px;
}
}

.num-pag {
    margin-right: 3px;
}

.filter {
    margin-left: 10px;
}

.date-picker-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1024px) {
    .date-picker-container {
        flex-direction: row;
    }
}

.picker {
    padding-left: 10px;
}

.react-datepicker-wrapper {
    padding: 10px !important;
}

