.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100vh; /* Adjust this value to control the vertical centering */
    text-align: center;
    background-color: #f5f5f5; /* Change to your desired background color */
    /*background-image: url('../assets/cebada.png');*/
    
    background-repeat: no-repeat;
    background-attachment: fixed; /* Keep the background fixed as the content scrolls */
    background-position: right bottom; /* Position at right bottom corner */
    
  }
  
  .home-container h1 {
    font-size: 2rem;
    margin-bottom: 10px;
    padding-top: 15%;
  }
  
  .home-container h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #b78f61; /* Change to your desired text color */

  }
  
  .home-container h3 {
    font-size: 1.2rem;
  }
