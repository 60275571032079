.left-menu {
    width: 20%; /* Set the width of the left menu */
    /*background-color: #E9C397; /* Set the background color of the left menu */
    background-color: #495057;
    min-width: 250px;
    overflow-y: auto;
  }
  
.left-menu ul {
  list-style-type: none; /* Remove default list styles */
  padding: 20px;
  padding-top: 50px;
}

.left-menu li {
  color:#CED4DA;
  padding: 10px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease;
}

.menu-icon {
  padding-right: 20px;
}

.observations-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical; /* Allow vertical resizing */
}

.left-menu li:hover {
  background-color: #E9C397; /* Change to your desired hover color */
  color: white; /* Change to a suitable text color for the hover state */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add a subtle shadow on hover */
}

.menu-logo {
  display: flex;
  flex-direction: column; /* Stack logo and title vertically */
  align-items: center;
}

.menu-language {
  display: flex;
  flex-direction: column; /* Stack logo and title vertically */
  align-items: center;
  padding-bottom: 25px;
}

.logo-icon {
  max-width: 100%; /* This ensures the image is responsive and doesn't overflow */
  height: 50px; /* Maintain aspect ratio */
  padding-bottom: 10px;
}

.menu-title {
  text-align: center; /* This centers your title text */
  color: #cfc8c8; /* Change the color as needed */
  margin-top: 8px; /* Add space between the logo and the title */
  font-size: 20px; /* Change the font size as needed */
  /* Add more styling as needed */
}

.left-menu li.active {
  /*background-color: #E9C397; /* Example: Change background color for active item */
  background-color: #f0b577;
  color: white;
  /* Add other styles as needed */
}

/* Asegúrate de que la clase 'variety' esté correctamente estilizada */
.variety {
  width: 50px;
  height: 50px;
}

/* Si el SVG es un elemento inline dentro de HTML */
.svg-inline {
  width: 50px;
  height: 50px;
}