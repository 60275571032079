/* Sample Image Container */
.sample-images-container {
  max-height: 600px; /* Adjust the maximum height as needed */
  overflow-y: scroll;
  padding: 15px;
}

/* Sample Image Row */
.sample-image-row {
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px; /* Add desired padding */
  padding-left: 50px;
  padding-right: 50px;
  border: 2px solid darkgrey; /* Add desired border style */
  margin-bottom: 10px; /* Add desired margin between rows */
  /* Add any other styles for the sample image row */
  justify-content: space-between;
  align-items: center;
}


.sample-image-row {
  background-color: #ffffff; /* Default background */
  transition: background-color 0.3s; /* Smooth transition for background color */
  padding-top: 10px;
}

.sample-image-row:hover {
  background-color: #e5dfdf; /* Light grey color on hover */
}


.sample-image-open {
  padding-right: 25px;
}

/* Thumbnails */
.sample-image-thumbnails {
  height: 80px;
  width: 80px;
  padding: 10px; /* Add desired padding */
  /* Add styles for the thumbnails */
}

/* Icons */
.sample-image-icons {
  
  padding: 10px; /* Add desired padding */
  /* Add styles for the icons */
  display: flex; /* This will enable flexbox layout */
  justify-content: center; /* This will center the icon horizontally */
  align-items: center; /* This will center the icon vertically */
  height: 80px;
  width: 80px;
}

/* Sample Image Index */
.sample-image-index {
  /* Add styles for the number */
  display: flex;
  align-items: center;
  
}

.sample-image-hydration {
  width: 145px;
  display: flex;
  align-items: center;
  min-width: 180px;
  

}

/* Sample Image Index */
.sample-image-filename {
  /* Add styles for the number */
  display: flex;
  align-items: center;
  width: 60px !important; 

}

/* Sample Image Capacity */
.sample-image-capacity {
  /* Add styles for the number */
  display: flex;
  padding-right: 50px;
  align-items: center;
  width: 220px;
  height: 30px;

}

.sample-image-date {
  /* Add styles for the number */
  display: flex;
  padding-right: 0px;
  width: 450px;
  height: 30px;
}

.image-icon {
  width: 100%;
  height: 100%;
  border: 2px solid darkgrey; /* Add desired border style */

}

.edit-icon img  {
  width: 100%;
  height: 100%;
  /*border: 2px solid darkgrey; /* Add desired border style */

}

.edit-icon {
  width: 50%;
  height: 50%;
  /*border: 2px solid darkgrey; /* Add desired border style */
  padding: 0;
  display: inline-block;
  overflow: hidden;  /* Ensures nothing spills out of the button */

}

.edit-icon.enabled {
  background-color: transparent;
}

.edit-icon.disabled {
  background-color: #ff5c33;
}


.toggle-image {
  transition: transform 0.3s ease-in-out;
  width: 20px;
  height: 20px;
}

.edit-icon.active .toggle-image {
  transform: scale(1.1);
  
}

.thumbnail-image {
  width: 100%;
  height: 100%;
  max-width: 100%;
  border: 2px solid darkgrey; /* Add desired border style */

}

.sample-info-name{
  display: flex;
  padding-right: 15%;
  padding-left: 15%;
  width: 750px;
  max-width: 500px;
}

.sample-info-batch{
  display: flex;
  padding-right: 15%;
  padding-left: 15%;
  width: 500px;
}

.sample-info-hydration{
  padding-left: 10%;
  justify-content: space-around;
  flex-direction: column;
}

.sample-view-header{
  display: flex;
  flex-wrap: nowrap;
  padding-top: 30px;
  padding-bottom: 10px;

}

.sample-view-info {
  width: 50%;
}

.actions-container {
  width: 100%;
  height: 20%;
  padding-bottom: 15px;
  padding-left: 50px;
  display: flex;
  justify-content: space-between; /* This spaces out the buttons maximally */
  align-items: flex-start; /* Align items to the top */
    /* Add any additional styling as needed */
  
}

.notes {
  width: 90%;
  height: 100px;
  resize: none; /* Disables resizing */
}

.notes-container {
  width: 50%;
  height: 150px;
}

.modal-content {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 90%; /* Set the maximum width of the modal content */
  max-height: 90%; /* Set the maximum height of the modal content */
  margin: 0 auto;
  padding-top: 50px;
}

.close-button {
  position: absolute;
  top: 40%;    /* Center vertically */
  right: -22px; /* 15px from the right edge */
  transform: translateY(-50%);
  display: flex;
  align-items: end;
}

.modal-image-container {
  
  position: relative;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
}

.index-value {
  font-size: 20px;
  font-weight: normal;
  color:aliceblue;
}

.disabled-icon {
  height: "100%";
  width: "100%";
}

.canvas-container {
  width: 70%;
  height: 70%;
  overflow: hidden; 
  max-width: 90%; /* Set the maximum width of the modal content */
  max-height: 90%; /* Set the maximum height of the modal content */
  display: flex;
  z-index: 1;
  position: relative;
  justify-content: center;
  align-items: center;
}

.active {
  background-color: green;
}

.inactive {
  background-color: red;
}

.status-icon {
  height: 35px; /* or your preferred size */
}


.sample-image-row.disabled {
  background-color: #cd7763; /* Red background for disabled rows */
}

.arrow-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 24px;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.buttons-container {
  position: fixed; /* Fixed position relative to the viewport */
  bottom: 10vh; /* Adjust this value as needed for different screens */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Align center */
  display: flex;
  justify-content: space-around; /* Adjust as needed */
  width: 50%; /* Adjust the width as needed */
  z-index: 1000; /* Ensure it's above other elements */
}

.button {
  padding: 10px 20px; /* Adjust padding as needed */
  margin: 0 10px; /* Spacing between buttons */
  /* Add other styling for buttons */
}

.modal-navigation-left, .modal-navigation-right {
  background-color: #f0b577; /* Green background */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px; /* Rounded corners */
  transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.modal-navigation-left:hover, .modal-navigation-right:hover {
  background-color: #f0b577; /* Darker shade of green for hover effect */
}

.modal-navigation-left i, .modal-navigation-right i {
  border: solid white;
  border-width: 0 4px 4px 0;
  display: inline-block;
  padding: 3px;
}

.modal-navigation-left i {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.modal-navigation-right i {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.modal-data-container{
  position:absolute;
  display:flex;
  flex-direction: column;
  align-items: center;
  top: 25px; 
  left:43%
}


.filename-text {
  
  right: 0;
  color: white;
  text-align: center; 
  font-size: 20px; 
  padding-bottom: 5px;
}

.modal-percentage {
  
  
  left: 0;
  right: 0;
  color: white;
  text-align: center; 
  font-size: 20px; 
}

.edited-dot {
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}


.filename-header {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px;
  border-radius: 5px;
  position: absolute;
  top: 10px; /* Adjust to move down from the top */
  left: 50%;
  transform: translateX(-50%);
  z-index: 100; /* Ensure it's on top of everything */
  width: auto;  /* Width adjusted based on content */
  pointer-events: none; /* Ensure clicks pass through */
}

.contour-edition-container {
  position: relative;
  width: 1024px;
  height: 1024px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.canvas-container {
  position: relative;
  width: 1024px;
  height: 1024px;
}

.fabric-canvas {
  width: 1024px;
  height: 1024px;
  border: 1px solid #ccc;
}