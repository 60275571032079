/* Styles.css or your appropriate CSS file */
.centered-form-container {
    
    justify-content: center;
    align-items: center;
    width: 600px;
    padding: 100px;
}

.form-field {
    width: 500px;
}
@media screen and (max-width: 1024px) {
   .form-field {
      width: 250px;
   }
 }

.users-container {
    display: flex;
    justify-content: space-between; /* This will keep some space between the two sections */
    padding: 20px;
 }

 @media screen and (max-width: 1024px) {
   .users-container {
      padding-left: 75px;
   }
 }
 
 .user-list {
    width: 60%; /* Adjust based on your preference */
    border-left: 1px solid #e0e0e0; /* Optional, adds a border between the list and form */
    padding-left: 20px;
 }
 
 .create-user-form {
    width: 35%; /* Adjust based on your preference */
 }

 .edit-button {
    padding: 15px;
    margin: 5px;
 }

 .users-container {
   display: flex;
   justify-content: space-around;
   padding: 20px;
   width: 100%;
   gap: 20px;
}

.users-list {
   flex: 1;
   padding: 20px;
   background-color: #f9f9f9;
   border-radius: 8px;
   box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.create-user-form {
   flex: 1;
   padding: 20px;
   background-color: #f9f9f9;
   border-radius: 8px;
   box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

table {
   width: 100%;
   border-collapse: collapse;
   margin-top: 20px;
}

table, th, td {
   border: 1px solid #ddd;
}

th, td {
   text-align: left;
   padding: 8px;
}

th {
   background-color: #d4c17a;
   color: white;
}

tr:nth-child(even) {
   background-color: #f2f2f2;
}

tr:hover {
   background-color: #ddd;
}

.btn {
   border: none;
   color: white;
   padding: 10px 20px;
   text-align: center;
   text-decoration: none;
   display: inline-block;
   font-size: 16px;
   margin: 4px 2px;
   transition: 0.3s;
   cursor: pointer;
   border-radius: 5px;
}

.btn-primary {
   background-color: #4CAF50;
}

.btn-primary:hover {
   background-color: #45a049;
}

.btn-delete {
   background-color: #f44336;
}

.btn-delete:hover {
   background-color: #da190b;
}

.form-field {
   margin-bottom: 15px;
   width: 100%;
}

label {
   display: block;
   font-weight: bold;
}

#email, #username, #password{
   width: 100%;
   margin: 5px 0 0px 0;
   display: inline-block;
   border: 1px solid #ccc;
   border-radius: 4px;
   box-sizing: border-box;
}

.success-message, .error-message {
   color: white;
   padding: 10px;
   margin-bottom: 20px;
   border-radius: 5px;
   text-align: center;
}

.success-message {
   background-color: #4CAF50;
}

.error-message {
   background-color: #f44336;
}

.content {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: space-around;
   padding: 20px;
   width: 100%;
   gap: 20px;
 }

@media screen and (max-width: 768px) {
   .users-container {
       flex-direction: column;
   }

   .users-list, .create-user-form {
       width: 100%;
   }
}


.spinner-container {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh; /* or adjust based on your layout */
}


.spinner {
   display: inline-block;
   position: relative;
   width: 80px;
   height: 80px;
 }
 
 .spinner div {
   position: absolute;
   border: 4px solid #6f85c7;
   opacity: 1;
   border-radius: 50%;
   animation: spinner 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
 }
 
 .spinner div:nth-child(1) {
   animation-delay: -0.3s;
 }
 .spinner div:nth-child(2) {
   animation-delay: -0.2s;
 }
 .spinner div:nth-child(3) {
   animation-delay: -0.1s;
 }
 
 @keyframes spinner {
   0% {
       top: 36px;
       left: 36px;
       width: 0;
       height: 0;
       opacity: 1;
   }
   100% {
       top: 0px;
       left: 0px;
       width: 72px;
       height: 72px;
       opacity: 0;
   }
 }
 