.card {
    height: 100px;
    width:100px;
    padding:10px;
  }
  
  .thumbnail {
    height: 100px;
    width: 80px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    object-fit: cover;
  }
  
  .container {
    width: 70%;
    height: 70vh;
    border-radius: 10px;
    padding: 50px;
    margin: 50px;
    display: flex;
    flex-direction: column; /* Make this column to organize contents vertically */
    justify-content: flex-start;
    align-items: flex-start;
  }

  .images-container {
    padding: 80px;
    height: 80vh;
    border-radius: 10px;

  }

  @media screen and (max-width: 1024px) {
    .images-container {
       padding-left: 80px;
    }
  }
  
  .thumbnails {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    height: auto; 
    max-height: 70%; /* So it takes at most half the container's height */
    overflow-y: auto; /* To scroll when content overflows */
    width: 100%; /* Ensuring it spans the entire width of the container */
    margin-bottom: 20px; /* To give space between thumbnails and the button */
  }

.process-button {
  background-color: #E9C397;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.process-button:disabled {
  cursor: not-allowed;
  background-color: #D1D1D1;
}

.process-button.loading {
  position: relative;
}

.process-button.loading::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-top-color: #E9C397;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: translate(-50%, -50%) rotate(0deg); }
  100% { transform: translate(-50%, -50%) rotate(360deg); }
}

button {
  background-color: #4675a9
}

.add-buttons {
  display: flex;
  align-items: center;
  gap: 20px; /* Optional: adds space between the file and folder sections */
  padding-bottom: 20px;
}

.paragraph {
  margin-top: revert-layer;
}